/* eslint-disable @typescript-eslint/no-var-requires */
import getAppTenantIdTsg from "./utils/get-app-tenant-id-tsg";
import getAppTenantId from "./utils/get-app-tenant-id";
import getDIAppTenantRegion from "./utils/getAppTenantRegion";
import { HOST_APP_DI, HOST_APP_FAWKES } from "@reporting-n-viz/vnr-entrypoint/dist/host-app";
import { aiopsAuthDataMutation } from "./utils/aiops-authdata-mutation";
import "./refs/dashboards";

/* Warning: please do not statically import any dependencies here except for static assets like icons */

const init = async ({ vars, onAuthed, sparkyFramework }: any) => {
  onAuthed(async (params: any) => {
    params.getFeatures();
    params.getLicenseInfo();
    params.getFqdnInfo();

    return await Promise.all([sparkyFramework.loaded, params.getAuthInfo()])
      .then(async () => await import("@reporting-n-viz/vnr-entrypoint/dist/api-prefetch"))
      .then(({ prefetch }) => {
        const getCsIdTsg = require("./utils/get-cs-id-tsg").default;
        const getCsId = require("./utils/get-cs-id").default;
        const getLsTenantIdTsg = require("./utils/get-ls-tenant-id-tsg").default;
        const getLsTenantId = require("./utils/get-ls-tenant-id").default;
        const getLsTenantLogData = require("./utils/get-ls-tenant-log-data").default;
        const getInstanceTier = require("./utils/get-instance-tier").default;
        const getSparkyMainState = require("./utils/get-sparky-main-state").default;
        const getManagedAppName = require("./utils/get-managed-app-name").default;
        const getPaParams = require("./utils/get-pa-params").default;
        const getSdwanOnlyFlag = require("./utils/get-sdwan-only-flag").default;
        const getMfeProps = require("./utils/get-mfe-properties").default;
        const hasTsgSupport = sparkyFramework.hasTsgSupport();
        const main = getSparkyMainState(
          hasTsgSupport,
          sparkyFramework.getAuthState,
          sparkyFramework.getMainState,
          sparkyFramework.StartupManager
        );
        const { paiFQDN } = getPaParams(hasTsgSupport, main, sparkyFramework.StartupManager);
        let appTenantRegion = main?.tenant?.info?.region;
        let authData = hasTsgSupport
          ? sparkyFramework.getAuthState()
          : sparkyFramework.getAuthData(main.tenant, main.authInfo, main.loggedInUser);
        const isPAEnabled = main?.tenant?.pa_enabled ?? authData?.instances?.isFawkesRunning ?? false;
        const currentInstanceTier = getInstanceTier()?.frameworkInstance;
        const isHybridFramework = getInstanceTier()?.isHybridFramework;
        const isSDWANOnlyTenant = getSdwanOnlyFlag(authData, currentInstanceTier);
        const hasCDL = authData?.instances?.isRunningStatus("logging_service");
        const paiFeatures = sparkyFramework.getState()?.saseIAMainState?.cosmosFeatures;
        const lsTenantLogData = getLsTenantLogData(authData);
        let managedAppName = getManagedAppName(hasTsgSupport, sparkyFramework.getAuthState, sparkyFramework.StartupManager);
        let di;
        let prismaAccess;
        const adem = extractAdemParams(sparkyFramework, authData);
        const appTenantIdPrismaAccess = hasTsgSupport
          ? getAppTenantIdTsg(authData, "prisma_access")
          : undefined;
        if (appTenantIdPrismaAccess && isPAEnabled) {
          prismaAccess = {
            appTenantId: appTenantIdPrismaAccess,
            appTenantRegion,
            appType: managedAppName
          };
        }

        const aiopsAppId =
          currentInstanceTier === "premium" ? "strata_insights" : "strata_insights_free";
        if (
          hasTsgSupport &&
          // eslint-disable-next-line @typescript-eslint/naming-convention
          (authData.instances.some(({ app_id }: any) => app_id === aiopsAppId) ||
            isSDWANOnlyTenant)
        ) {
          managedAppName = isSDWANOnlyTenant ? "cgx" : "strata_insights";
          authData = aiopsAuthDataMutation(authData, currentInstanceTier);
          appTenantRegion = getDIAppTenantRegion(managedAppName, authData);
          di = {
            appTenantId: getAppTenantIdTsg(authData, managedAppName),
            appTenantRegion,
            appType: managedAppName
          };
        }
        const hostApp = [
          "strata_insights_free",
          "strata_insights",
          "strata_insights_ngfw",
          "cgx"
        ].includes(managedAppName)
          ? HOST_APP_DI
          : HOST_APP_FAWKES;
        const appTenantId = hasTsgSupport
          ? getAppTenantIdTsg(authData, managedAppName)
          : getAppTenantId(main);

        return prefetch(
          vars.api_server_url,
          {
            tokenManager: hasTsgSupport
              ? null
              : sparkyFramework.getTokenManager(
                main.tenant,
                main.authInfo,
                main.loggedInUser
              ),
            csId: hasTsgSupport ? getCsIdTsg(authData, managedAppName) : getCsId(main),
            tsgId: authData.tsg_id,
            appsToTenantMap: new Map([[managedAppName, new Set([appTenantId])]]),
            hasTsgSupport,
            getCurrentTsgToken: sparkyFramework.getCurrentTsgToken,
            hostApp,
            managedAppName,
            appTenantId,
            authData,
            loggedInUser: main?.loggedInUser,
            appTenantRegion: appTenantRegion || "us",
            isEarlyAccessNgfwTenant: main?.tenant?.is_early_access_ngfw_tenant || false,
            isSDWANOnlyTenant,
            lsTenantId: hasTsgSupport
              ? getLsTenantIdTsg(authData)
              : getLsTenantId(main),
            currentInstanceTier,
            isHybridFramework,
            hasCDL,
            notTelemetryTenant: lsTenantLogData,
            di,
            prismaAccess,
            adem,
            paiFQDN,
            mfeProps: getMfeProps(),
            paiFeatures
          },
          sparkyFramework.actions.setValues
        );
      });
  });

  return await import(/* webpackPreload: true */ "./routes").then((r) => r.default);
};

init.exposes = {
  UARComponent: async () => await import("./UAR"),
  Dashboard: async () => await import("./Dashboard"),
  CoPilot: async () => await import("./CoPilot")
};

function extractAdemParams (sparkyFramework: any, authData: any) {
  const apiServerUrl = sparkyFramework?.getMicroAppVars("dem")?.api_server_url;
  const tsgId = authData?.tsg_id;
  if (!apiServerUrl || !tsgId) {
    return undefined;
  }

  return {
    serverURL: apiServerUrl,
    apiMethod: `/api/v3/t/${tsgId}`,
    headers: {
      'prisma-tenant': tsgId
    },
    tsgId
  };
}
export default init;
